import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ArticlePreview from "../components/articlePreview";

// create a new component called Articles 
// note it uses 3 components: Layout, SEO and ArticlePreview 
// it creates 'articles' ...simply a short version of 'data.allNodeArticle.nodes'
// data.allNodeArticle.nodes comes from the GraphQL query at the bottom of this file 
// this line is looping over that data
// {articles.map(article => ( 
// map is an Javascript method for looping 
// see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map  
// the loop creates muitple ArticlePreview components 
// in a list, one for each article  

const Articles = ({ data }) => {
  const articles = data.allNodeArticle.nodes;
  return (
    <Layout>
      <SEO title="Articles" />
      <h1>Articles</h1>
      <p>These articles are being pulled into Gatsby from this site hosting on Pantheon: http://dev-drupalrhc.pantheonsite.io/</p>
      <p>During the Gatsby build process, which is being done on Netlify, JSON data from the Drupal site
        is pulled into GraphQL then React within Gatsby uses that data and Gastby builds the static files. </p>
      {articles.map(article => (
        <ArticlePreview
          key={article.id}
          title={article.title}
          path={article.path.alias}
          image={article.relationships.field_image.localFile.childImageSharp.fluid}
          alt={article.field_image.alt}
          summary={article.body.summary ? article.body.summary : article.body.processed.substring(0, 300)}
        />
      ))}
    </Layout>
  );
}

// below is optional but recommended
// see more about React propTypes here
// https://reactjs.org/docs/typechecking-with-proptypes.html
Articles.propTypes = {
  data: PropTypes.object.isRequired,
};

// create the object 'data' which contains the graphql query 
// the data object is used above in the Article component 
export const data = graphql`
  {
    allNodeArticle(sort: {fields: created, order: DESC}) {
      nodes {
        title
        id
        body {
          summary
          processed
        }
        created
        field_image {
          alt
        }
        path {
          alias
        }
        relationships {
          field_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

// export the Aricles component (you do this will all React components)
export default Articles;

// NOTES 
// one thing to note about the query is that to get the image itself you need to 
// go into relationships, because image is an entity references from within the node
//
// TO DO add info about this:
// childImageSharp {
//         fluid(maxWidth: 600) {
//           ...GatsbyImageSharpFluid
//         }
//       }